@import "../../Styles/Globals.scss";

.CTAScreen {
  .step-container {
    align-items: center;
  }
  

  h1, h2 {
    text-align: center;
  }

  .mapContainer {
    width: min(100%, 400px);
    height: 400px;
    
    border-radius: 15px;
    overflow: hidden;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 1000px) {
      justify-content: center;
      align-items: center;
      gap: 20px;

      .cta {
        &:not(.cta2) {
          margin-left: 0 !important;
        }
      }
    }

    .cta {
      padding: 10px 25px;
      line-height: 1;

      background-color: $primary-color;
      border-radius: 50px;
      border: 2px solid transparent;

      font-size: clamp(16px, 1.8vw, 20px);
      font-weight: 500;
      
      color: white;
      line-height: 1;
      cursor: pointer;

      &:not(.cta2) {
        margin-top: 15px;

        @media screen and (max-width: 700px) {
          margin-top: 0;
        }
      }

      &.cta2 {
        background-color: transparent;
        border-color: $primary-color;
        color: #f5f5f5;
      }

      &.--small {
        font-size: clamp(14px, 1.6vw, 16px);
      }

      &.disabled {
        opacity: .3;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .mobileDivisor {
      height: 20px;
    }
  }
}