@import "../../Styles/Globals.scss";

.loadingScreen {
  z-index: 10;
  position: fixed;top: 100vh;left: 0;right: 0;bottom: 0;
  display: grid;place-items: center;
  padding: 40px;
  
  width: 100%;
  height: 100vh;

  background-color: $primary-color;
  transition: all .5s;
  
  .ls_ti {
    color: white;
  }

  &.active {
    top: 0;
  }
}